import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ `relations`, `todoings` ]

  initialize() {
  }

  connect() {
  }

  disconnect() {
  }

  get todoId() {
    return this.data.get('todo-id')
  }

  createTodoing(event) {
    // Add Todoing for user
    fetch('/todoings', {
      method: 'POST',
      headers: {
        'Accept': 'application/javascript',
        'Content-Type': 'application/json',
        'X-CSRF_Token': Rails.csrfToken(),
      },
      body: JSON.stringify({
        todo_id: this.todoId,
        todoing: {
          todo_id: this.todoId,
          user_id: event.currentTarget.dataset['userId'],
        }
      }),
    }).then(response => response.text())
    .then(html => {
      var template = document.createElement('template')
      template.innerHTML = html.trim()
      this.todoingsTarget.prepend(template.content.firstChild)
    }).catch(error => alert('Error adding person to fun-do.'))
  }

  destroyTodoing(event) {
    // Add Todoing for user
    fetch(`/todoings/${event.currentTarget.dataset['todoingId']}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/javascript',
        'X-CSRF_Token': Rails.csrfToken(),
      },
    }).then(response => {
      event.target.parentElement.remove()
    }).catch(error => alert('Error removing person from fun-do.'))
  }

  toggleInvite(event) {
    this.relationsTarget.classList.toggle('hidden')
  }
}
