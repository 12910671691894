import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ `email`, `submit` ]

  initialize() {}

  connect() {
    if (this.emailTarget) {
      this.emailTarget.focus()
      this.emailTarget.addEventListener(`keyup`, this.isEmailPopulated.bind(this))
      this.isEmailPopulated()
    }
  }

  disconnect() {
    if (this.emailTarget) {
      this.emailTarget.removeEventListener(`keyup`)
    }
  }

  isEmailPopulated(event) {
    if (this.email.length > 1) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  get email() {
    return this.emailTarget.value
  }
}
