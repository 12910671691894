import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ `title` ]

  initialize() {
  }

  connect() {
    this.titleTarget.value = localStorage.getItem('newItem');
    // Sometimes the browser clears storage before setting the target value.
    // Thus, delay it by a second.
    setTimeout(function() {
      localStorage.setItem('newItem', '');
    }, 1000);
  }

  disconnect() {
  }
}
