import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ `name`, `submit` ]

  initialize() {}

  connect() {
    if (this.nameTarget) {
      this.nameTarget.focus()
      this.nameTarget.addEventListener(`keyup`, this.isNamePopulated.bind(this))
      this.isNamePopulated()
    }
  }

  disconnect() {
    if (this.nameTarget) {
      this.nameTarget.removeEventListener(`keyup`)
    }
  }

  isNamePopulated(event) {
    if (this.name.length > 1) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  get name() {
    return this.nameTarget.value
  }
}
