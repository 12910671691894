import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ `relationshipType` ]

  initialize() {
    this.relationshipTypes = [
      `partner`,
      `friend`,
      `colleague`,
    ]
    this.index = -1
  }

  connect() {
    this.updateRelationshipType()
    this.cycleTimer = setInterval(() => {
      this.updateRelationshipType()
    }, 2000)
  }

  updateRelationshipType() {
    if (this.index+1 >= this.relationshipTypes.length) {
      this.index = 0
    } else {
      this.index = this.index + 1
    }
    this.relationshipTypeTarget.textContent = this.relationshipTypes[this.index]
  }

  disconnect() {
    this.stopUpdating()
  }

  stopUpdating() {
    if (this.cycleTimer) {
      clearInterval(this.cycleTimer)
    }
  }
}
