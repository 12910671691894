import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'mobileNav', 'newItem' ]

  initialize() {
  }

  connect() {
    if (navigator.platform == `Win32`) {
      // Find all data-platform='mac' elements and hide them
      document.querySelectorAll("[data-platform='mac']").forEach(e => e.remove())
    }

    if (navigator.platform == `MacIntel`) {
      // Find all data-platform='win' elements and hide them
      document.querySelectorAll("[data-platform='win']").forEach(e => e.remove())
    }

    window.tribute.attach(this.newItemTarget)
  }

  disconnect() {
  }

  updateNewItem() {
    localStorage.setItem('newItem', this.newItemTarget.value)
  }

  openMobileNav() {
    this.mobileNavTarget.classList.add('open')
    document.body.classList.add('fixed')
    window.scrollTo(0, 0)
    this.newItemTarget.focus()
  }

  closeMobileNav() {
    this.mobileNavTarget.classList.remove('open')
    document.body.classList.remove('fixed')
  }

  toggleMobileNav() {
    if (this.mobileNavTarget.classList.contains('open')) {
      this.closeMobileNav()
    } else {
      this.openMobileNav()
    }
  }
}
