import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ `input`, `emojiDropdown`, `emojiDropdownToggle` ]

  initialize() {
  }

  connect() {
    this.input_id = this.data.get("inputId")
    window.tribute.attach(this.inputTarget)
  }

  disconnect() {
  }

  emoji_selected(event) {
    // Append emoji to input
    // TODO: Allow emojis to be inserted anywhere in text, not just at the end
    this.inputTarget.value = this.inputTarget.value + event.srcElement.innerHTML
    // Hide emoji modal after emoji is selected
    this.emojiDropdownToggleTarget.dispatchEvent(new MouseEvent('click'))
  }
}
