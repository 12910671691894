import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize() {}

  connect() {
    document.querySelectorAll("input[type='radio']").forEach((radio) => {
      if (radio.checked) {
        radio.parentElement.classList.add('selected')
      }
    })
  }

  disconnect() {}

  radioButtonSelected(event) {
    let radioButtons = event.currentTarget.parentElement.children
    for (let i = 0; i < radioButtons.length; i++) {
      radioButtons[i].classList.remove('selected')
    }
    event.currentTarget.classList.add('selected')
  }
}
