import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ `` ]

  initialize() {
  }

  connect() {
  }

  disconnect() {
  }

  get todoId() {
    return this.data.get("todo-id")
  }
}
