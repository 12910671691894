/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import * as ActiveStorage from "@rails/activestorage"
import Flatpickr from "stimulus-flatpickr"
import "flatpickr/dist/themes/dark.css"
import "../css/application.css"
import Tribute from "tributejs";

const application = Application.start()
ActiveStorage.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

application.register("flatpickr", Flatpickr)

window.tribute = new Tribute({
  values: [
    { key: "Sean Abrahams", value: "sean" },
    { key: "Sophie Sills", value: "sophie" },
  ],
});

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' })
    .then(function(reg) {
      console.log('[Companion]', 'Service worker registered!');
      console.log(reg);
    });
}
